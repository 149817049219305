<template>
  <b-row>
    <b-col cols="7">
      <div class="areawrap">
        <b-row>
          <b-col cols="8">
            <template v-if="step === 1">
              <h2 class="px-4">
                Corporate Information
              </h2>
              <hr>
              <div class="px-4">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Corporate Name"
                      class="mb-6"
                      :state="$v.form.$anyDirty ? !$v.form.corporate_name.$anyError : null"
                    >
                      <b-form-input
                        v-model="form.corporate_name"
                        placeholder="Enter Corporate Name"
                      />
                      <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_name.required : null">
                        corporate name is required
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Street Address"
                      class="mb-6"
                      :state="$v.form.$anyDirty ? !$v.form.corporate_address.$anyError : null"
                    >
                      <b-form-input v-model="form.corporate_address" placeholder="Enter Address" />
                      <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_address.required : null">
                        Street address is required
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="City"
                      class="mb-6"
                      :state="$v.form.$anyDirty ? !$v.form.corporate_city.$anyError : null"
                    >
                      <b-form-input v-model="form.corporate_city" placeholder="Enter City" />
                      <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_city.required : null">
                        city is required
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <div class="mb-4">
                      <b-form-group
                        label="Country"
                        class="mb-6"
                        :state="$v.form.$anyDirty ? !$v.form.corporate_country.$anyError : null"
                      >
                        <b-form-select
                          v-model="form.corporate_country"
                          :options="countryList"
                          :state="$v.form.$anyDirty ? !$v.form.corporate_country.$anyError : null"
                          @change="onCountryChange"
                        >
                          <template #first>
                            <b-form-select-option :value="null">
                              Select Country
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_country.required : null">
                          country is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Telephone"
                      class="mb-6"
                      :state="$v.form.$anyDirty ? !$v.form.corporate_telphone.$anyError : null"
                    >
                      <b-form-input v-model="form.corporate_telphone" placeholder="Enter Telephone" />
                      <b-form-invalid-feedback
                        :state="$v.form.$anyDirty ? $v.form.corporate_telphone.required : null"
                      >
                        telephone is required
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Operation Type">
                      <b-form-radio-group v-model="operationType">
                        <b-form-radio :value="0">
                          Service Provider
                        </b-form-radio>
                        <b-form-radio :value="1">
                          Distributor
                        </b-form-radio>
                        <b-form-radio :value="2">
                          Both
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </template>

            <template v-else>
              <h2 class="px-4">
                Owner Information
              </h2>
              <hr>
              <div class="px-4">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Full Name"
                      class="mb-6"
                      :state="$v.form.$anyDirty ? !$v.form.name.$anyError : null"
                    >
                      <b-form-input v-model="form.name" placeholder="Enter Owner Name" />
                      <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.name.required : null">
                        owner name is required
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Email"
                      class="mb-6"
                      :state="$v.form.$anyDirty ? !$v.form.email.$anyError : null"
                    >
                      <b-form-input v-model="form.email" placeholder="Enter Email Address" />
                      <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.required : null">
                        email address is required
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.email : null">
                        email address format is incorrect
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.repeated : null">
                        email address is repeated
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Mobile Phone (Optional)">
                      <b-form-input v-model="form.mobilePhone" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Phone Extension (Optional)">
                      <b-form-input v-model="form.extension" placeholder="Default" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </template>
            <hr>
          </b-col>

          <b-col cols="4">
            <ul class="partner-list list-unstyled font-size-sm mt-16">
              <li class="partner-list-item active mb-4">
                <span class="partner-list-number mr-2">1</span>
                Corporate Information
              </li>
              <li class="partner-list-item" :class="{ 'active': step === 2 }">
                <span class="partner-list-number mr-2">2</span>
                Owner Information
              </li>
            </ul>
          </b-col>
        </b-row>

        <div class="d-flex px-4">
          <template v-if="step === 1">
            <b-button variant="primary" class="btn-width" @click="nextStep">
              NEXT
            </b-button>
          </template>
          <template v-else>
            <b-button variant="primary" class="btn-width mr-4" @click="submit">
              FINISH
            </b-button>
            <b-button variant="light" class="btn-width" @click="step = 1">
              BACK
            </b-button>
          </template>

          <b-button variant="light" class="btn-width ml-auto" :to="{ name: 'PartnerList' }">
            CANCEL
          </b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import countryList, { getCountryCode } from '@/common/countryList'
import { createPartner } from '@/api/partner'

export default {
  name: 'Create',
  data () {
    return {
      step: 1,

      form: {
        corporate_name: '',
        corporate_country: null,
        corporate_city: null,
        corporate_address: '',
        corporate_telphone: '',
        service_provide: true,
        distributor: true,

        name: '',
        email: '',
        extension: '',
        mobilePhone: ''
      },

      emailRepetList: []
    }
  },
  validations () {
    return {
      form: {
        corporate_name: { required },
        corporate_country: { required },
        corporate_city: { required },
        corporate_address: { required },
        corporate_telphone: { required },

        name: { required },
        email: {
          required,
          email,
          repeated (value) {
            if (!this.form.email) return true
            return this.emailRepetList.indexOf(value) === -1
          }
        }
      },

      step1: [
        'form.corporate_name',
        'form.corporate_address',
        'form.corporate_city',
        'form.corporate_country',
        'form.corporate_telphone'
      ]
    }
  },
  computed: {
    countryList () {
      return countryList
    },
    operationType: {
      get () {
        if (this.form.service_provide && this.form.distributor) return 2
        else if (this.form.service_provide) return 0
        else return 1
      },
      set (value) {
        this.form.service_provide = value === 0 || value === 2
        this.form.distributor = value === 1 || value === 2
      }
    }
  },
  created () {
  },
  methods: {
    nextStep () {
      this.$v.step1.$touch()
      if (this.$v.step1.$anyError) return
      this.$v.$reset()
      this.step = 2
    },
    onCountryChange (value) {
      this.form.corporate_telphone = `+${getCountryCode(value)}`
    },
    submit () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      createPartner(this.form).then(() => {
        this.$router.push({ name: 'PartnerList' })
      }).catch(() => {
        if (this.emailRepetList.indexOf(this.form.email) !== -1) return
        this.emailRepetList.push(this.form.email)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.partner-list {
  font-weight: 500;
  color: $gray-500;

  &-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.3rem;
    height: 1.3rem;
    color: #fff;
    background-color: $gray-500;
    border-radius: 50%;
    padding-bottom: 2px;
  }

  &-item {
    display: flex;
    align-items: center;

    &.active {
      color: $primary;

      .partner-list-number {
        background-color: $primary;
      }
    }
  }
}
</style>
