import apiService from '@/common/api.service'

export function getDashboard () {
  return apiService({
    url: '/api/partner/dashboard',
    method: 'get'
  })
}
export function getPartnerList () {
  return apiService({
    url: '/api/partner/list',
    method: 'get'
  })
}

export function getPartnerData (id) {
  return apiService({
    url: '/api/partner',
    method: 'get',
    params: { id }
  })
}

export function createPartner (data) {
  return apiService({
    url: '/api/partner',
    method: 'post',
    data
  })
}

export function updatePartnerCorporateData (data) {
  return apiService({
    url: '/api/partner/corporate',
    method: 'patch',
    data
  })
}

export function updatePartnerOwnerData (data) {
  return apiService({
    url: '/api/partner/owner',
    method: 'patch',
    data
  })
}

export function updatePartnerInitData (data) {
  return apiService({
    url: '/api/partner/initialization',
    method: 'patch',
    data
  })
}

export function enablePartner (data) {
  return apiService({
    url: '/api/partner/enabled',
    method: 'patch',
    data
  })
}
